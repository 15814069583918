import React from 'react';
import _ from 'lodash';
import {
  Atelier5Banner,
  Atelier5Logo,
  ApoVBBanner,
  ApoVBLogo,
  HeliosBanner,
  HeliosLogo,
  KoffieNBanner,
  KoffieNLogo,
} from '../images/sqip';

const partners = [
  // {
  //   alias: '',
  //   name: '',
  //   banner: '',
  //   logo: '',
  //   french: false,
  //   description: <React.Fragment></React.Fragment>,
  //   hours: <React.Fragment></React.Fragment>,
  //   contact: { street: '', city: '', website: '', mail: '', phones: [{ number: '', link: '' }] },
  //   filters: [{ filter: '' }],
  //   numOfImages: 1,
  //   googleQuerry: ''
  // },
  {
    alias: 'atelier5',
    name: 'Atelier 5',
    banner: <Atelier5Banner />,
    logo: <Atelier5Logo />,
    french: false,
    description: (
      <React.Fragment>
        <p className='large-title heavy margin'>Kapsalon</p>
        <p className='partner-text'>Dames &#124; Heren &#124; Kinderen</p>
      </React.Fragment>
    ),
    hours: (
      <React.Fragment>
        <table style={{ width: '100%', textAlign: 'center' }}>
          <tbody>
            <tr>
              <td>
                <p className='partner-text heavy blue'>Dames & meisjes</p>
              </td>
              <td>
                <p className='partner-text heavy blue'>Heren & jongens</p>
              </td>
            </tr>
            <tr>
              <td>
                <p className='partner-text blue'>ma. en di. gesloten</p>
              </td>
              <td>
                <p className='partner-text blue'>ma. en di. gesloten</p>
              </td>
            </tr>
            <tr>
              <td>
                <p className='partner-text blue'>woe. op afspraak</p>
              </td>
              <td>
                <p className='partner-text blue'>woe. 16u tot 20u</p>
              </td>
            </tr>
            <tr>
              <td>
                <p className='partner-text blue'>do. 9u tot 18u*</p>
              </td>
              <td>
                <p className='partner-text blue'>do. 16u tot 20u</p>
              </td>
            </tr>
            <tr>
              <td>
                <p className='partner-text blue'>vr. 9u tot 19u*</p>
              </td>
              <td>
                <p className='partner-text blue'>vr. 8u tot 20u</p>
              </td>
            </tr>
            <tr>
              <td>
                <p className='partner-text blue'>za. 8u tot 16u*</p>
              </td>
              <td>
                <p className='partner-text blue'>za. 8u tot 16u</p>
              </td>
            </tr>
            <tr>
              <td>
                <p className='partner-text blue small'>* enkel op afspraak</p>
              </td>
            </tr>
          </tbody>
        </table>
      </React.Fragment>
    ),
    contact: {
      street: 'Henri Dunantlaan 5',
      city: '3400 Landen',
      phones: [
        { number: '0470 22 08 34', link: '+32470220834', extra: '(dames)' },
        { number: '0470 59 01 53', link: '+32470590153', extra: '(heren)' },
      ],
      facebook: 'https://www.facebook.com/KapsalonAtelier5',
    },
    filters: [{ filter: 'Kapsalon' }],
    numOfImages: 11,
    googleQuerry: 'Henri%20Dunantlaan%205%2C%203400%20Landen%2C%20Belgi%C3%AB',
  },
  {
    alias: 'apotheekvanbrabant',
    name: 'Apotheek Vanbrabant',
    banner: <ApoVBBanner />,
    logo: <ApoVBLogo />,
    french: false,
    description: (
      <React.Fragment>
        <p className='large-title heavy margin'>Apotheek Vanbrabant</p>
        <p className='partner-text margin'>
          Ons team staat steeds voor u klaar met een gepast en professioneel advies.
        </p>
        <p className='partner-text'>
          Door continue bijscholing zijn we altijd op de hoogte van de nieuwste producten en aanbevelingen.
        </p>
      </React.Fragment>
    ),
    hours: (
      <React.Fragment>
        <p className='partner-text blue' style={{ marginBottom: '3px' }}>
          <strong className='partner-text heavy blue'>Maandag </strong>
          t.e.m.
          <strong className='partner-text heavy blue'> vrijdag</strong>
        </p>
        <p className='partner-text blue'>van 8u30 tot 12u30</p>
        <p className='partner-text margin blue'>en van 13u30 tot 19u</p>
        <p className='partner-text blue' style={{ marginBottom: '3px' }}>
          <strong className='partner-text heavy blue'>Zaterdag </strong>
        </p>
        <p className='partner-text blue'>van 8u30 tot 12u30</p>
      </React.Fragment>
    ),
    contact: {
      street: 'Bontgenotenlaan 2',
      city: '3400 Landen',
      phones: [{ number: '011 88 38 51', link: '+3211883851' }],
      website: 'apotheekvanbrabant.be',
      mail: 'info@apotheekvanbrabant.be',
      facebook: 'https://www.facebook.com/apotheekvanbrabant',
    },
    filters: [{ filter: 'Apotheek' }],
    numOfImages: 11,
    googleQuerry: 'Apotheek+Vanbrabant',
  },
  {
    alias: 'helios',
    name: 'Helios',
    banner: <HeliosBanner />,
    logo: <HeliosLogo />,
    french: false,
    description: (
      <React.Fragment>
        <p className='large-title heavy small-margin'>Zonnecentrum Helios</p>
        <p className='partner-text margin'>Automatisch zonnecentrum te Landen.</p>
      </React.Fragment>
    ),
    hours: (
      <React.Fragment>
        <p className='partner-text blue'>
          <strong className='partner-text heavy blue'>Elke dag</strong> open
        </p>
        <p className='partner-text blue'>van 8u30 tot 23u</p>
      </React.Fragment>
    ),
    contact: {
      street: 'Kerkstraat 6',
      city: '3400 Landen',
      phones: [{ number: '0478 25 39 76', link: '+32478253976' }],
      facebook: 'https://www.facebook.com/Automatisch-zonnecentrum-Helios-270426226359813/?epa=SEARCH_BOX',
    },
    filters: [{ filter: 'Zonnencentrum' }],
    numOfImages: 10,
    googleQuerry: 'Kerkstraat+6,+3400+Landen',
  },
  {
    alias: 'koffienoe',
    name: 'Koffie Noé',
    banner: <KoffieNBanner />,
    logo: <KoffieNLogo />,
    french: true,
    description: (
      <React.Fragment>
        <p className='large-title heavy margin'>Koffie Noé</p>
        <p className='partner-text margin'>Al onze koffiesoorten zijn gemalen verkrijgbaar of in bonen.</p>
        <p className='partner-text margin'>
          De koffie kan gratis aan huis geleverd worden binnen een straal van 25 km rond Landen.
        </p>
        <p className='partner-text'>Vanaf 9 kg leveren wij gratis aan huis in heel België.</p>
      </React.Fragment>
    ),
    hours: (
      <React.Fragment>
        <p className='partner-text blue' style={{ marginBottom: '3px' }}>
          <strong className='partner-text heavy blue'>Maandag </strong>
          en
          <strong className='partner-text heavy blue'> dinsdag</strong>
        </p>
        <p className='partner-text blue'>van 9u tot 12u</p>
        <p className='partner-text margin blue'>en van 13u tot 17u</p>
        <p className='partner-text blue' style={{ marginBottom: '3px' }}>
          <strong className='partner-text heavy blue'>Donderdag </strong>
          en
          <strong className='partner-text heavy blue'> vrijdag</strong>
        </p>
        <p className='partner-text blue'>van 9u tot 12u</p>
        <p className='partner-text margin blue'>en van 13u tot 17u</p>
      </React.Fragment>
    ),
    contact: {
      street: 'Bormansstraat 18/20',
      city: '3401 Walshoutem',
      mail: 'bvbanoe@skynet.be',
      phones: [
        { number: '0497 57 22 75', link: '+32497572275' },
        { number: '0494 24 91 29', link: '+32494249129' },
      ],
      facebook: 'https://www.facebook.com/Koffie-No%C3%A9-46663436596/',
    },
    filters: [{ filter: 'Ambacht' }],
    numOfImages: 13,
    googleQuerry: 'Noé+Koffie',
    notification: {
      title: 'Opgelet',
      content: (
        <React.Fragment>
          <p className='partner-text margin'>Wij zijn gesloten van 20 december tot en met 5 januari.</p>
          <p className='partner-text'>Prettige feestdagen!</p>
          <p className='partner-text'>Koffie Noé</p>
        </React.Fragment>
      ),
    },
  },
];

const frenchPartners = [
  {
    alias: 'atelier5',
    name: 'Atelier 5',
    banner: <Atelier5Banner />,
    logo: <Atelier5Logo />,
    french: false,
    description: (
      <React.Fragment>
        <p className='large-title heavy margin'>Kapsalon</p>
        <p className='partner-text'>Dames &#124; Heren &#124; Kinderen</p>
      </React.Fragment>
    ),
    hours: (
      <React.Fragment>
        <table style={{ width: '100%', textAlign: 'center' }}>
          <tbody>
            <tr>
              <td>
                <p className='partner-text heavy blue'>Dames & meisjes</p>
              </td>
              <td>
                <p className='partner-text heavy blue'>Heren & jongens</p>
              </td>
            </tr>
            <tr>
              <td>
                <p className='partner-text blue'>ma. en di. gesloten</p>
              </td>
              <td>
                <p className='partner-text blue'>ma. en di. gesloten</p>
              </td>
            </tr>
            <tr>
              <td>
                <p className='partner-text blue'>woe. op afspraak</p>
              </td>
              <td>
                <p className='partner-text blue'>woe. 16u tot 20u</p>
              </td>
            </tr>
            <tr>
              <td>
                <p className='partner-text blue'>do. 9u tot 18u*</p>
              </td>
              <td>
                <p className='partner-text blue'>do. 16u tot 20u</p>
              </td>
            </tr>
            <tr>
              <td>
                <p className='partner-text blue'>vr. 9u tot 19u*</p>
              </td>
              <td>
                <p className='partner-text blue'>vr. 8u tot 20u</p>
              </td>
            </tr>
            <tr>
              <td>
                <p className='partner-text blue'>za. 8u tot 16u*</p>
              </td>
              <td>
                <p className='partner-text blue'>za. 8u tot 16u</p>
              </td>
            </tr>
            <tr>
              <td>
                <p className='partner-text blue small'>* enkel op afspraak</p>
              </td>
            </tr>
          </tbody>
        </table>
      </React.Fragment>
    ),
    contact: {
      street: 'Henri Dunantlaan 5',
      city: '3400 Landen',
      phones: [
        { number: '0470 22 08 34', link: '+32470220834', extra: '(dames)' },
        { number: '0470 59 01 53', link: '+32470590153', extra: '(heren)' },
      ],
      facebook: 'https://www.facebook.com/KapsalonAtelier5',
    },
    filters: [{ filter: 'Kapsalon' }],
    numOfImages: 11,
    googleQuerry: 'Henri%20Dunantlaan%205%2C%203400%20Landen%2C%20Belgi%C3%AB',
  },
  {
    alias: 'apotheekvanbrabant',
    name: 'Apotheek Vanbrabant',
    banner: <ApoVBBanner />,
    logo: <ApoVBLogo />,
    french: false,
    description: (
      <React.Fragment>
        <p className='large-title heavy margin'>Apotheek Vanbrabant</p>
        <p className='partner-text margin'>
          Ons team staat steeds voor u klaar met een gepast en professioneel advies.
        </p>
        <p className='partner-text'>
          Door continue bijscholing zijn we altijd op de hoogte van de nieuwste producten en aanbevelingen.
        </p>
      </React.Fragment>
    ),
    hours: (
      <React.Fragment>
        <p className='partner-text blue' style={{ marginBottom: '3px' }}>
          <strong className='partner-text heavy blue'>Maandag </strong>
          t.e.m.
          <strong className='partner-text heavy blue'> vrijdag</strong>
        </p>
        <p className='partner-text blue'>van 8u30 tot 12u30</p>
        <p className='partner-text margin blue'>en van 13u30 tot 19u</p>
        <p className='partner-text blue' style={{ marginBottom: '3px' }}>
          <strong className='partner-text heavy blue'>Zaterdag </strong>
        </p>
        <p className='partner-text blue'>van 8u30 tot 12u30</p>
      </React.Fragment>
    ),
    contact: {
      street: 'Bontgenotenlaan 2',
      city: '3400 Landen',
      phones: [{ number: '011 88 38 51', link: '+3211883851' }],
      website: 'apotheekvanbrabant.be',
      mail: 'info@apotheekvanbrabant.be',
      facebook: 'https://www.facebook.com/apotheekvanbrabant',
    },
    filters: [{ filter: 'Apotheek' }],
    numOfImages: 11,
    googleQuerry: 'Apotheek+Vanbrabant',
  },
  {
    alias: 'helios',
    name: 'Helios',
    banner: <HeliosBanner />,
    logo: <HeliosLogo />,
    french: false,
    description: (
      <React.Fragment>
        <p className='large-title heavy small-margin'>Zonnecentrum Helios</p>
        <p className='partner-text margin'>Automatisch zonnecentrum te Landen.</p>
      </React.Fragment>
    ),
    hours: (
      <React.Fragment>
        <p className='partner-text blue'>
          <strong className='partner-text heavy blue'>Elke dag</strong> open
        </p>
        <p className='partner-text blue'>van 8u30 tot 23u</p>
      </React.Fragment>
    ),
    contact: {
      street: 'Kerkstraat 6',
      city: '3400 Landen',
      phones: [{ number: '0478 25 39 76', link: '+32478253976' }],
      facebook: 'https://www.facebook.com/Automatisch-zonnecentrum-Helios-270426226359813/?epa=SEARCH_BOX',
    },
    filters: [{ filter: 'Zonnencentrum' }],
    numOfImages: 10,
    googleQuerry: 'Kerkstraat+6,+3400+Landen',
  },
  {
    alias: 'koffienoe',
    name: 'Café Noé',
    banner: <KoffieNBanner />,
    logo: <KoffieNLogo />,
    french: true,
    description: (
      <React.Fragment>
        <p className='large-title heavy margin'>Café Noé</p>
        <p className='partner-text margin'>Tous nos cafés sont disponibles moulus ou en grains.</p>
        <p className='partner-text margin'>
          Le café peut être livré gratuitement dans un rayon de 25 km autour de Landen.
        </p>
        <p className='partner-text'>A partir de 9 kg, nous livrons gratuitement à domicile dans toute la Belgique.</p>
      </React.Fragment>
    ),
    hours: (
      <React.Fragment>
        <p className='partner-text blue' style={{ marginBottom: '3px' }}>
          <strong className='partner-text heavy blue'>Lundi </strong>
          et
          <strong className='partner-text heavy blue'> Mardi</strong>
        </p>
        <p className='partner-text blue'>de 9h à 12h</p>
        <p className='partner-text margin blue'>et de 13h à 17h</p>
        <p className='partner-text blue' style={{ marginBottom: '3px' }}>
          <strong className='partner-text heavy blue'>Jeudi </strong>
          et
          <strong className='partner-text heavy blue'> Vendredi</strong>
        </p>
        <p className='partner-text blue'>de 9h à 12h</p>
        <p className='partner-text margin blue'>et de 13h à 17h</p>
      </React.Fragment>
    ),
    contact: {
      street: 'Bormansstraat 18/20',
      city: '3401 Walshoutem',
      mail: 'bvbanoe@skynet.be',
      phones: [
        { number: '0497 57 22 75', link: '+32497572275' },
        { number: '0494 24 91 29', link: '+32494249129' },
      ],
      facebook: 'https://www.facebook.com/Koffie-No%C3%A9-46663436596/',
    },
    filters: [{ filter: 'Ambacht' }],
    numOfImages: 13,
    googleQuerry: 'Noé+Koffie',
    notification: {
      title: 'Attention',
      content: (
        <React.Fragment>
          <p className='partner-text margin'>Nous sommes fermés du 20 décembre jusqu'au 5 janvier.</p>
          <p className='partner-text'>Joyeuses fêtes!</p>
          <p className='partner-text'>Café Noé</p>
        </React.Fragment>
      ),
    },
  },
];

export function getPartners() {
  return _.shuffle(partners);
}

export function getPartner(alias) {
  return partners.filter((partner) => partner.alias === alias)[0];
}

export function getFrenchPartner(alias) {
  return frenchPartners.filter((partner) => partner.alias === alias)[0];
}

export function getFilters() {
  let output = [];
  partners
    .map((partner) => partner.filters)
    .forEach((filters) => {
      output = _.unionBy(output, filters, 'filter');
    });
  return output;
}
